import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Home.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { environment } from "./environment/environment";

const HomeComponent = () => {
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    fetchTournaments();
  }, []);

  const fetchTournaments = async () => {
    try {
      const response = await axios.get(
        environment.api_url + "/api/v1/tournaments"
      );
      setTournaments(response.data.data);
    } catch (error) {
      console.error("Error fetching tournaments:", error);
    }
  };

  return (
    <div>
      <div className="home-container">
        <div className="create-new-tour-btn w-100 d-flex flex-row mt-5 justify-content-end">
          {/* <Link to="/create-new-tournament">
            <button className="btn btn-lg cr-btn">
              Create New Tournament +
            </button>
          </Link> */}
        </div>
        <div className="available-tour mt-5">
          <ul className="available-tour-list">
            {tournaments.map((tournament) => (
              <li key={tournament._id} className="d-flex">
                <Link to={`/tournament-preview/${tournament._id}`}>
                  {tournament.name}
                </Link>

                <Link to={`/tournament-plan/${tournament._id}`}>
                  <button className="btn btn-sm btn-primary edit-btn">
                    Plan Tournament <FontAwesomeIcon icon={faEdit} />
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
