import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/ScoreBoard.css";
import { environment } from "./environment/environment";

const ScoreBoardNew2Component = () => {
    const { matchId } = useParams();
    const [match, setMatch] = useState(null);
    const [currentBatsman1, setCurrentBatsman1] = useState("");
    const [currentBatsman2, setCurrentBatsman2] = useState("");
    const [currentBowler, setCurrentBowler] = useState("");
    const [ballRuns, setBallRuns] = useState(0);
    const [currentBat, setCurrentBat] = useState("");
    const [runStatus, setRunStatus] = useState("RUNS");
    const [ballExtraRuns, setBallExtraRuns] = useState(0);
    const [extraRunType, setExtraRunType] = useState("");
    const [redirectUrl, setRedirectUrl] = useState(null);

    useEffect(() => {
        fetchTournament();
    }, [matchId]);

    const fetchTournament = async () => {
        try {
            const response = await fetch(environment.api_url + `/api/v1/match-details/${matchId}`);

            if (response.ok) {
                const matchData = await response.json();
                
                setMatch(matchData.data);

                console.log(matchData.data);

                if(matchData.data.batSecondScoreboard && matchData.data.batSecondScoreboard.currentlyBatting.length > 0) {
                    setCurrentBatsman1(matchData.data.batSecondScoreboard.currentlyBatting[0]);
                }

                if(matchData.data.batSecondScoreboard && matchData.data.batSecondScoreboard.currentlyBatting.length > 1) {
                    setCurrentBatsman2(matchData.data.batSecondScoreboard.currentlyBatting[1]);
                }

                if(matchData.data.batSecondScoreboard && matchData.data.batSecondScoreboard.currentlyBowling) {
                    setCurrentBowler(matchData.data.batSecondScoreboard.currentlyBowling);
                }
            } else {
                console.error("Failed to fetch match data");
            }
        } catch (error) {
            console.error("Error while fetching match data:", error);
        }
    };


    if (match === null) {
        return <div className="loading">Loading...</div>;
    }

    const playToss = (teams) => {
        return <div>
            { teams[0].name }

            &nbsp; VS &nbsp;

            { teams[1].name }

            <br/><br/>

            <a href="/play-toss/" className="btn btn-success">Play Toss</a>
        </div>
    };

    const batSecondTeam = (batFirst, teams) => {
        var batSecondTeam = teams.filter(function (t) {
            return t.id != batFirst;
        });

        return batSecondTeam[0];
    }

    const ballSecondTeam = (batFirst, teams) => {
        var ballSecondTeam = teams.filter(function (t) {
            return t.id == batFirst;
        });

        return ballSecondTeam[0];
    }
    
    const getBatterRuns = (playerId, bowls) => {
        if(bowls == null) {
            return "-";
        }

        var playerRuns = 0;

        bowls.filter(function (b) {
            if(b.status == "RUNS" && b.batsmanId == playerId) {
                playerRuns = playerRuns + b.runs;
            }
        });

        return playerRuns;
    }

    const getBowlerRuns = (playerId, bowls) => {
        if(bowls == null) {
            return "-";
        }

        var bowlerRuns = 0;

        bowls.filter(function (b) {
            if(b.status != "WICKET" && b.bowlerId == playerId) {
                bowlerRuns = bowlerRuns + b.runs;
            }
        });

        return bowlerRuns;
    }

    const getBowlerWickets = (playerId, bowls) => {
        if(bowls == null) {
            return "-";
        }

        var bowlerWickets = 0;

        bowls.filter(function (b) {
            if(b.status == "WICKET" && b.bowlerId == playerId) {
                bowlerWickets = bowlerWickets + 1;
            }
        });

        return bowlerWickets;
    }

    const getBatFirstTotalRuns = (bowls) => {
        if(bowls == null) {
            return "-";
        }

        var totalRuns = 0;
        var totalWickets = 0;

        bowls.filter(function (b) {
            if(b.status != "WICKET") {
                totalRuns = totalRuns + b.runs;
            }

            if(b.status == "WICKET") {
                totalWickets = totalWickets + 1;
            }
        });

        return totalRuns + "/" + totalWickets;
    }

    const getTotalWides = (bowls) => {
        if(bowls == null) {
            return "-";
        }

        var totalWides = 0;

        bowls.filter(function (b) {
            if(b.status == "WIDE") {
                totalWides = totalWides + b.runs;
            }
        });

        return totalWides;
    }

    const getTotalNoBalls = (bowls) => {
        if(bowls == null) {
            return "-";
        }

        var totalNoBalls = 0;

        bowls.filter(function (b) {
            if(b.status == "NO_BALL") {
                totalNoBalls = totalNoBalls + b.runs;
            }
        });

        return totalNoBalls;
    }

    const getTotalLegByes = (bowls) => {
        if(bowls == null) {
            return "-";
        }

        var totalLegByes = 0;

        bowls.filter(function (b) {
            if(b.status == "LEG_BYE") {
                totalLegByes = totalLegByes + b.runs;
            }
        });

        return totalLegByes;
    }

    const getPlayer = (playerId, teamPlayers) => {
        if(!playerId) {
            return "";
        }

        var player = teamPlayers.filter(function (p) {
            return p._id == playerId;
        });

        return player[0];
    }

    const getSortedBowling = (playerBowls) => {
        return playerBowls.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            return dateB - 
            
            dateA;
        });
    }

    const updateCurrentPlayers = async () => {
        if(match.status == "NOT_STARTED") {
            alert("Please play toss first!");

            return false;
        }

        if(match.status == "BAT_FIRST_WIN" || match.status == "BALL_FIRST_WIN" || match.status == "MATCH_TIED") {
            alert("Maytch is already finalized!");

            return false;
        }

        if(!currentBatsman1) {
            alert("Please select first batsman!");

            return false;
        }

        if(!currentBowler) {
            alert("Please select current bowler!");
            
            return false;
        }

        if(currentBatsman1 == currentBatsman2) {
            alert("Same player selected as both batsman1 & batsman2");

            return false;
        }

        var currentPlayers = [];

        if(currentBatsman1) {
            currentPlayers.push(currentBatsman1);
        }

        if(currentBatsman2) {
            currentPlayers.push(currentBatsman2);
        }

        await fetch(environment.api_url + `/api/v1/update-current-players`, {
            method: 'POST',
            body: JSON.stringify({
                "matchId": match._id,
                "battingTeamId": batSecondTeam(match.batFirst, match.teams)._id,
                "currentlyBattingPlayers" : currentPlayers,
                "currentlyBowlingPlayer": currentBowler
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json()).then((data) => {
            fetchTournament();

            if(!data.success) {
                alert(data.message);
            }
        }).catch((err) => {
            console.log(err.message);
        });
    }
    
    const updateRuns = async () => {
        if(match.status == "NOT_STARTED") {
            alert("Please play toss first!");

            return false;
        }

        if(match.status == "BAT_FIRST_WIN" || match.status == "BALL_FIRST_WIN" || match.status == "MATCH_TIED") {
            alert("Maytch is already finalized!");

            return false;
        }

        if(!currentBat) {
            alert("Please select current batsman!");

            return false;
        }

        if(!currentBowler) {
            alert("Please select current bowler!");

            return false;
        }

        await fetch(environment.api_url + `/api/v1/update-score`, {
            method: 'POST',
            body: JSON.stringify({
                "matchId": match._id,
                "battingTeamId": batSecondTeam(match.batFirst, match.teams)._id,
                "currentBattingPlayer" : currentBat,
                "currentBowlingPlayer": currentBowler,
                "runs": ballRuns,
                "status": runStatus
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json()).then((data) => {
            fetchTournament();
            setCurrentBat("");
            setBallRuns(0);
            setBallExtraRuns(0);
            setRunStatus("RUNS");

            if(!data.success) {
                alert(data.message);
            }
        }).catch((err) => {
            console.log(err.message);
        });
    }

    const updateExtraRuns = async () => {
        if(match.status == "BAT_FIRST_WIN" || match.status == "BALL_FIRST_WIN" || match.status == "MATCH_TIED") {
            alert("Maytch is already finalized!");

            return false;
        }

        if(!currentBowler) {
            alert("Please select current bowler!");

            return false;
        }

        if(!extraRunType) {
            alert("Please select extra run type!");

            return false;
        }

        if(ballExtraRuns == 0) {
            alert("Extra run count should not be 0!");

            return false;
        }

        await fetch(environment.api_url + `/api/v1/update-extra-run`, {
            method: 'POST',
            body: JSON.stringify({
                "matchId": match._id,
                "battingTeamId": batSecondTeam(match.batFirst, match.teams)._id,
                "currentBowlingPlayer": currentBowler,
                "runs": ballExtraRuns,
                "status": extraRunType
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json()).then((data) => {
            fetchTournament();
            setCurrentBat("");
            setBallRuns(0);
            setBallExtraRuns(0);
            setExtraRunType("");
            setRunStatus("RUNS");

            if(!data.success) {
                alert(data.message);
            }
        }).catch((err) => {
            console.log(err.message);
        });
    }
    
    const deleteLastBall = async () => {
        if(match.status == "BAT_FIRST_WIN" || match.status == "BALL_FIRST_WIN" || match.status == "MATCH_TIED") {
            alert("Maytch is already finalized!");

            return false;
        }

        if(window.confirm("Are you sure want to delete last ball?") == true) {
            await fetch(environment.api_url + `/api/v1/delete-last-ball`, {
                method: 'POST',
                body: JSON.stringify({
                    "matchId": match._id,
                    "battingTeamId": batSecondTeam(match.batFirst, match.teams)._id
                }),
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                },
            }).then((response) => response.json()).then((data) => {
                fetchTournament();
                setCurrentBat("");
                setBallRuns(0);
                setBallExtraRuns(0);
                setExtraRunType("");
                setRunStatus("RUNS");
    
                if(!data.success) {
                    alert(data.message);
                }
            }).catch((err) => {
                console.log(err.message);
            });
        }
    }

    const getSingleBallClass = (ballStatus) => {
        if(ballStatus == "WIDE") {
            return "wide-ball";
        } else if(ballStatus == "NO_BALL") {
            return "no-ball";
        } else if(ballStatus == "LEG_BYE") {
            return "legbye-ball";
        } else if(ballStatus == "WICKET") {
            return "wicket-ball";
        } else {
            return "normal-ball";
        }
    }

    const finalizeMatch = async () => {
        if(match.status == "BAT_FIRST_WIN" || match.status == "BALL_FIRST_WIN" || match.status == "MATCH_TIED") {
            alert("Maytch is already finalized!");

            return false;
        }
        
        if(window.confirm("Are you sure want to finalize match?")) {
            if(!match.batFirstScoreboard) {
                alert("Please update scores on first innings!");
                
                return false;
            }

            if(!match.batSecondScoreboard) {
                alert("Please update scores on second innings!");

                return false;
            }

            try {
                const response = await fetch(environment.api_url + '/api/v1/match/finalize', {
                    method: 'POST',
                    body: JSON.stringify({
                        matchId: match._id,
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                });
    
                const data = await response.json();
    
                if (data.status === 200) {
    
                    console.log("tournamentId", match.tournamentId._id);
                    const newRedirectUrl = `/tournament-preview/${match.tournamentId._id}`;
                    setRedirectUrl(newRedirectUrl);
                }
                else {
                    alert("Error when finalizing match. " + data.message);
                }
            } catch (error) {
                console.log(error.message);
            }
        }
    };

    if (redirectUrl) {
        return <Redirect to={redirectUrl} />;
    }

    return (
        <>
            {
                match.batFirst != null ? <>
                    <div>
                        <div className="dashboard">
                            <div className="grid-container">
                                <div className="grid-item shadow batting-update">
                                    <div className="container batting-update">
                                        <div className="title-bat-update">
                                            <div className="score-head">
                                                <div className="sh1">
                                                    <h4 className="match-head">
                                                        { match.teams[0].name }

                                                        &nbsp; VS &nbsp;

                                                        { match.teams[1].name }
                                                    </h4>
                                                </div>

                                                <div className="sh2">
                                                    <h4 className="match-head">Bat Second: {batSecondTeam(match.batFirst, match.teams).name}</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container bat-update-data">
                                            <div className="bat-data-table-titles">
                                                <div className="batter-name">Batter</div>
                                                <div className="on-strike">Currently Playing</div>
                                                <div className="runs-scored">Runs</div>
                                            </div>

                                            <div className="divider"></div>

                                            <div className="data-container">
                                                <div className="player-names-and-data">
                                                    <div className="batter-names">
                                                        <div className="batter-name1">
                                                            <div className="player-name">
                                                                <h5>Batsman 1</h5>

                                                                <select className="form-select shadow"
                                                                    value={currentBatsman1}
                                                                    onChange={(e) =>
                                                                        setCurrentBatsman1((prev) => (e.target.value))
                                                                    }
                                                                >
                                                                    <option value="">No One</option>
                                                                    {
                                                                        batSecondTeam(match.batFirst, match.teams).players.map((player, index) => (  
                                                                            <option value={player._id} key={index}>{player.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="player-name">
                                                                <h5>Batsman 2</h5>

                                                                <select className="form-select shadow"
                                                                    value={currentBatsman2}
                                                                    onChange={(e) =>
                                                                        setCurrentBatsman2((prev) => (e.target.value))
                                                                    }
                                                                >
                                                                    <option value="">No One</option>
                                                                    {
                                                                        batSecondTeam(match.batFirst, match.teams).players.map((player, index) => (  
                                                                            <option value={player._id} key={index}>{player.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="batter-name2">
                                                            <br/>
                                                            <button className="btn btn-success" onClick={updateCurrentPlayers}>Update Players</button>
                                                        </div>
                                                    </div>

                                                    <div className="player-data">
                                                        {
                                                            match.batSecondScoreboard && match.batSecondScoreboard.currentlyBatting.map((batterId, index) => (  
                                                                <div className="player-data1" key={index}>
                                                                    <div className="on-strike-data">
                                                                        <div className="selected-player">
                                                                            <div className="on-strike-data">
                                                                                {getPlayer(batterId, batSecondTeam(match.batFirst, match.teams).players).name}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="on-strike-runs">
                                                                        <div className="selected-player">
                                                                            <div className="on-strike-runs">
                                                                                {getBatterRuns(batterId, match.batSecondScoreboard.playerBowls)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-item shadow bowling-update">
                                    <div className="container batting-update">
                                        <div className="title-bat-update">
                                        <div className="score-head">
                                                <div className="sh3">
                                                    <h4 className="match-head">
                                                        Balling Team : { ballSecondTeam(match.batFirst, match.teams).name }
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container bat-update-data">
                                            <div className="bat-data-table-titles">
                                                <div className="batter-name">Current Bowler</div>
                                                <div className="on-strike">Over</div>
                                                <div className="runs-scored">Runs/Wickets</div>
                                            </div>

                                            <div className="divider"></div>

                                            <div className="data-container">
                                                <div className="player-names-and-data">
                                                    <div className="batter-names">
                                                        <div className="player-name">
                                                            <select className="form-select shadow"
                                                                value={currentBowler}
                                                                onChange={(e) =>
                                                                    setCurrentBowler((prev) => (e.target.value))
                                                                }
                                                            >
                                                                <option value="">No One</option>
                                                                {
                                                                    ballSecondTeam(match.batFirst, match.teams).players.map((player, index) => (  
                                                                        <option value={player._id} key={index}>{player.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="player-data">
                                                        <div className="player-data1">
                                                            <div className="on-strike-data">
                                                                <div className="selected-player">
                                                                    <div className="on-strike-data">
                                                                        {match.batSecondScoreboard && getPlayer(match.batSecondScoreboard.currentlyBowling, ballSecondTeam(match.batFirst, match.teams).players).name}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="on-strike-runs">
                                                                <div className="selected-player">
                                                                    <div className="on-strike-runs">
                                                                        {match.batSecondScoreboard && getBowlerRuns(match.batSecondScoreboard.currentlyBowling, match.batSecondScoreboard.playerBowls)} / 
                                                                        {match.batSecondScoreboard && getBowlerWickets(match.batSecondScoreboard.currentlyBowling, match.batSecondScoreboard.playerBowls)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bowl-by-ball">
                                            {
                                                match.batSecondScoreboard && getSortedBowling(match.batSecondScoreboard.playerBowls).map((pb, index) => (  
                                                    <div className={"single-ball " + getSingleBallClass(pb.status) } key={index}>
                                                        <div className="bowl-numer">{pb.over}.{pb.ballNumber}</div>
                                                        <div className="bowl-runs">{pb.runs}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className="color-codes">
                                            <span className="color-circle wicket-ball"></span> Wicket

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <span className="color-circle wide-ball"></span> Wide

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <span className="color-circle no-ball"></span> No Ball

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <span className="color-circle legbye-ball"></span> Leg Bye

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                          

                                            <span className="color-circle normal-ball"></span> Normal
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-item shadow stat-board">
                                    <div className="score-summary-container">
                                        <div className="left-scoring">
                                            <div className="table-caption">Scoreboard</div>

                                            <div className="table-content score-sec0">
                                                {
                                                    match.batSecondScoreboard && batSecondTeam(match.batFirst, match.teams).players.map((player, index) => (
                                                        <div className="score-sec1" key={index}>
                                                            <div className="score-sec2">
                                                                <div className="p">{player.name}</div>
                                                            </div>
                                                            
                                                            <div className="score-sec2">
                                                                <div className="p">{getBatterRuns(player._id, match.batSecondScoreboard.playerBowls)}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                <div className="score-sec1">
                                                    <div className="score-sec2">
                                                        <div className="p"><b>Total</b></div>
                                                    </div>
                                                    
                                                    <div className="score-sec2">
                                                        <div className="p"><b>{match.batSecondScoreboard && getBatFirstTotalRuns(match.batSecondScoreboard.playerBowls)}</b></div>
                                                    </div>
                                                </div>
                                                
                                                <div className="score-sec3">
                                                    Wide: {match.batSecondScoreboard && getTotalWides(match.batSecondScoreboard.playerBowls)} &nbsp;&nbsp;&nbsp;&nbsp; No Ball: {match.batSecondScoreboard && getTotalNoBalls(match.batSecondScoreboard.playerBowls)} &nbsp;&nbsp;&nbsp;&nbsp; Leg Bye: {match.batSecondScoreboard && getTotalLegByes(match.batSecondScoreboard.playerBowls)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="right-bowling-figures">
                                            <div className="table-caption">Bowling Stat</div>
                                            
                                            <div className="table-content score-sec0">
                                                {
                                                    ballSecondTeam(match.batFirst, match.teams).players.map((player, index) => (
                                                        <div className="score-sec1" key={index}>
                                                            <div className="score-sec2">
                                                                <div className="p">{player.name}</div>
                                                            </div>
                                                            
                                                            <div className="score-sec2">
                                                                <p className="fs-3">{match.batSecondScoreboard && getBowlerRuns(player._id, match.batSecondScoreboard.playerBowls)}</p>
                                                                <p className="fs-5">/ {match.batSecondScoreboard && getBowlerWickets(player._id, match.batSecondScoreboard.playerBowls)}</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-item shadow score-update">
                                    <div className="bat-bowl-score-update">
                                        <div className="top-score-update">
                                            <div className="three-items">
                                                <div className="select-run">
                                                    <h5>Runs</h5>

                                                    <select className="form-select shadow score-dropdown"
                                                        value={ballRuns}
                                                        onChange={(e) =>
                                                            setBallRuns((prev) => (e.target.value))
                                                        }
                                                    >
                                                        <option value="0">0 run</option>
                                                        <option value="1">1 run</option>
                                                        <option value="2">2 runs</option>
                                                        <option value="3">3 runs</option>
                                                        <option value="4">4 runs</option>
                                                        <option value="5">5 runs</option>
                                                        <option value="6">6 runs</option>
                                                        <option value="7">7 runs</option>
                                                        <option value="8">8 runs</option>
                                                        <option value="9">9 runs</option>
                                                        <option value="10">10 runs</option>
                                                    </select>
                                                </div>

                                                <div className="divider-verticle"></div>

                                                <div className="select-run">
                                                    <h5>Run / Wicket</h5>

                                                    <select className="form-select shadow score-dropdown"
                                                        value={runStatus}
                                                        onChange={(e) =>
                                                            setRunStatus((prev) => (e.target.value))
                                                        }
                                                    >
                                                        <option value="RUNS">RUNS</option>
                                                        <option value="WICKET">WICKET</option>
                                                    </select>
                                                </div>

                                                <div className="divider-verticle"></div>

                                                <div className="select-batters">
                                                    <h5>Current Batter</h5>

                                                    <select className="form-select shadow score-dropdown"
                                                        value={currentBat}
                                                        onChange={(e) =>
                                                            setCurrentBat((prev) => (e.target.value))
                                                        }
                                                    >
                                                        <option value="">No One</option>
                                                        {
                                                            match.batSecondScoreboard && match.batSecondScoreboard.currentlyBatting.map((player, index) => (  
                                                                <option value={player} key={index}>{getPlayer(player, batSecondTeam(match.batFirst, match.teams).players).name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>

                                                <div className="divider-verticle"></div>
                                                
                                                <div className="select-on-strike">
                                                    <button className="btn btn-primary update-batter-score" onClick={updateRuns}>Update Runs</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="divider"></div>

                                        <div className="top-score-update">
                                            <div className="three-items">
                                                <div className="select-run">
                                                    <h5>Extra Runs</h5>

                                                    <select className="form-select shadow score-dropdown"
                                                        value={ballExtraRuns}
                                                        onChange={(e) =>
                                                            setBallExtraRuns((prev) => (e.target.value))
                                                        }
                                                    >
                                                        <option value="0">0 run</option>
                                                        <option value="1">1 run</option>
                                                        <option value="2">2 runs</option>
                                                        <option value="3">3 runs</option>
                                                        <option value="4">4 runs</option>
                                                        <option value="5">5 runs</option>
                                                        <option value="6">6 runs</option>
                                                        <option value="7">7 runs</option>
                                                        <option value="8">8 runs</option>
                                                        <option value="9">9 runs</option>
                                                        <option value="10">10 runs</option>
                                                    </select>
                                                </div>

                                                <div className="divider-verticle"></div>

                                                <div className="select-batters">
                                                    <h5>Extra Run Type</h5>

                                                    <select className="form-select shadow score-dropdown"
                                                        value={extraRunType}
                                                        onChange={(e) =>
                                                            setExtraRunType((prev) => (e.target.value))
                                                        }
                                                    >
                                                        <option value="">NONE</option>
                                                        <option value="WIDE">WIDE</option>
                                                        <option value="NO_BALL">NO BALL</option>
                                                        <option value="LEG_BYE">LEG BYE</option>
                                                    </select>
                                                </div>

                                                <div className="divider-verticle"></div>
                                                
                                                <div className="select-on-strike">
                                                    <button className="btn btn-primary update-batter-score b1" onClick={updateExtraRuns}>Update Extras</button>

                                                    <button className="btn btn-danger btn-sm b1" onClick={deleteLastBall}>Delete Last Ball</button>

                                                    <button className="btn btn-primary finalize-match-button" onClick={finalizeMatch}>Finalize Match</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <div className="open-div">
                        {
                            playToss(match.teams)
                        }
                    </div>
                </>
            }
        </>
    );
};

export default ScoreBoardNew2Component;
