import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { environment } from "./environment/environment";
import "../css/PointsTable.css";
import "bootstrap/dist/css/bootstrap.min.css";
const PointsTableComponent = () => {
    const { tournamentId } = useParams();
    const [teams, setTeams] = useState(null);
    const [allMatches, setAllMatches] = useState(null);
    const [allPlayers, setAllPlayers] = useState(null);

    useEffect(() => {
        fetchMatches();
    }, [tournamentId]);

    const fetchMatches = async () => {
        try {
            const allMatchesResponse = await fetch( environment.api_url + `/api/v1/matches-by-tournament/${tournamentId}`);

            if ( allMatchesResponse.ok) {
                const allMatchesData = await allMatchesResponse.json();
                setAllMatches(allMatchesData.data);
                // console.log("All Matches data:", allMatchesData.data);

                fetchTeams();
            } else {
                console.error("Failed to fetch match data");
            }
        } catch (error) {
            console.error("Error while fetching match data:", error);
        }
    };

    const fetchTeams = async () => {
        try {
            const teamsResponse = await fetch(
                environment.api_url + `/api/v1/teams/${tournamentId}`
            );

            if (teamsResponse.ok) {
                const teamData = await teamsResponse.json();
                setTeams(teamData.data);
                getAllPlayers();

                // console.log("Team data:", teamData.data);
            } else {
                console.error("Failed to fetch Team data");
            }
        } catch (error) {
            console.error("Error while fetching Team data:", error);
        }
    };

    const getTeamStatistics = () => {
        var sortedTeams = [];

        if(teams) {
            teams && teams.filter(function (team) {
                var playedMatches = 0;
                var wonMatches = 0;
                var lostMatches = 0;
                var tiedMatches = 0;
                var points = 0;

                allMatches.filter(function (match) {
                    if(match.type == "REGULAR") {
                        var teamFound = match.teams.filter(function (t) {
                            return t._id == team._id;
                        });
            
                        if(teamFound.length > 0) {
                            if(match.status == "BAT_FIRST_WIN") {
                                playedMatches = playedMatches + 1;
        
                                if(match.batFirst == team._id) {
                                    wonMatches = wonMatches + 1;
                                    points = points + 2;
                                } else {
                                    lostMatches = lostMatches + 1;
                                }
                            } else if(match.status == "BALL_FIRST_WIN") {
                                playedMatches = playedMatches + 1;
        
                                if(match.batFirst == team._id) {
                                    lostMatches = lostMatches + 1;
                                } else {
                                    wonMatches = wonMatches + 1;
                                    points = points + 2;
                                }
                            } else if(match.status == "MATCH_TIED") {
                                playedMatches = playedMatches + 1;
        
                                tiedMatches = tiedMatches + 1;
                                points = points + 1;
                            }
                        }
                    }
                });

                sortedTeams.push({
                    "name": team.name,
                    "playedMatches": playedMatches,
                    "wonMatches": wonMatches,
                    "lostMatches": lostMatches,
                    "tiedMatches": tiedMatches,
                    "points": points
                });
            });

            sortedTeams.sort(function (a, b) {
                var nameA = a.points, nameB = b.points;
            
                if (nameA < nameB)
                  return 1;
                if (nameA > nameB)
                  return -1;
                return 0;
            });

            return sortedTeams;
        } else {
            return [];
        }
    }
    
    const getAllPlayers = () => {
        var allPlayers = [];

        teams && teams.filter(function (team) {
            var teamMatches = allMatches.filter(function (m) {
                var teamFound = m.teams.filter(function (t) {
                    return t._id == team._id;
                });

                if(teamFound.length > 0) {
                    return true;
                }
            });

            team.players.filter(function (player) {
                var playerTotalRuns = 0;
                var playerTotalWicktes = 0;
                var playerTotalBoundaries = 0;
                
                teamMatches.filter(function (m) {
                    if(m.batFirst == team._id) {
                        m.batFirstScoreboard && m.batFirstScoreboard.playerBowls.filter(function (pb) {
                            if(pb._id == "64a4d4041349be3174650caa") {
                                console.log('Found1');
                            }

                            if(pb.status == "RUNS" && (pb.batsmanId == player._id)) {
                                playerTotalRuns = playerTotalRuns + pb.runs;

                                if(pb.runs == 4 || pb.runs == 6) {
                                    playerTotalBoundaries = playerTotalBoundaries + 1;
                                }
                            }

                            if(pb.status == "WICKET" && pb.bowlerId == player._id) {
                                playerTotalWicktes = playerTotalWicktes + 1;
                            }
                        });
                    } else {
                        m.batSecondScoreboard && m.batSecondScoreboard.playerBowls.filter(function (pb) {
                            if(pb._id == "64a4d4041349be3174650caa") {
                                console.log('Found2');
                            }

                            if(pb.status == "RUNS" && (pb.batsmanId == player._id)) {
                                playerTotalRuns = playerTotalRuns + pb.runs;

                                if(pb.runs == 4 || pb.runs == 6) {
                                    playerTotalBoundaries = playerTotalBoundaries + 1;
                                }
                            }

                            if(pb.status == "WICKET" && pb.bowlerId == player._id) {
                                playerTotalWicktes = playerTotalWicktes + 1;
                            }
                        });
                    }
                });

                allPlayers.push({
                    "name": player.name,
                    "playerTotalRuns": playerTotalRuns,
                    "playerTotalWicktes": playerTotalWicktes,
                    "playerTotalBoundaries": playerTotalBoundaries
                })
            });
        });

        return allPlayers;
    }

    const getAllPlayersWickets = () => {
        var allPlayers = [];

        teams && teams.filter(function (team) {
            var teamMatches = allMatches.filter(function (m) {
                var teamFound = m.teams.filter(function (t) {
                    return t._id == team._id;
                });

                if(teamFound.length > 0) {
                    return true;
                }
            });

            team.players.filter(function (player) {
                var playerTotalRuns = 0;
                var playerTotalWicktes = 0;
                var playerTotalBoundaries = 0;
                
                teamMatches.filter(function (m) {
                    if(m.batFirst == team._id) {
                        m.batSecondScoreboard && m.batSecondScoreboard.playerBowls.filter(function (pb) {
                            if(pb._id == "64a4d4041349be3174650caa") {
                                console.log('Found1');
                            }

                            if(pb.status == "RUNS" && (pb.batsmanId == player._id)) {
                                playerTotalRuns = playerTotalRuns + pb.runs;

                                if(pb.runs == 4 || pb.runs == 6) {
                                    playerTotalBoundaries = playerTotalBoundaries + 1;
                                }
                            }

                            if(pb.status == "WICKET" && pb.bowlerId == player._id) {
                                playerTotalWicktes = playerTotalWicktes + 1;
                            }
                        });
                    } else {
                        m.batFirstScoreboard && m.batFirstScoreboard.playerBowls.filter(function (pb) {
                            if(pb._id == "64a4d4041349be3174650caa") {
                                console.log('Found2');
                            }

                            if(pb.status == "RUNS" && (pb.batsmanId == player._id)) {
                                playerTotalRuns = playerTotalRuns + pb.runs;

                                if(pb.runs == 4 || pb.runs == 6) {
                                    playerTotalBoundaries = playerTotalBoundaries + 1;
                                }
                            }

                            if(pb.status == "WICKET" && pb.bowlerId == player._id) {
                                playerTotalWicktes = playerTotalWicktes + 1;
                            }
                        });
                    }
                });

                allPlayers.push({
                    "name": player.name,
                    "playerTotalRuns": playerTotalRuns,
                    "playerTotalWicktes": playerTotalWicktes,
                    "playerTotalBoundaries": playerTotalBoundaries
                })
            });
        });

        return allPlayers;
    }
    
    const getMostRuns = () => {
        var allPlayers = getAllPlayers();

        allPlayers.sort(function (a, b) {
            var nameA = a.playerTotalRuns, nameB = b.playerTotalRuns;
        
            if (nameA < nameB)
              return 1;
            if (nameA > nameB)
              return -1;
            return 0;
        });

        return allPlayers;
    }

    const getMostWickets = (allPlayers) => {
        var allPlayers = getAllPlayersWickets();

        allPlayers.sort(function (a, b) {
            var nameA = a.playerTotalWicktes, nameB = b.playerTotalWicktes;
        
            if (nameA < nameB)
              return 1;
            if (nameA > nameB)
              return -1;
            return 0;
        });

        return allPlayers;
    }

    const getMostBoundaries = (allPlayers) => {
        var allPlayers = getAllPlayers();

        allPlayers.sort(function (a, b) {
            var nameA = a.playerTotalBoundaries, nameB = b.playerTotalBoundaries;
        
            if (nameA < nameB)
              return 1;
            if (nameA > nameB)
              return -1;
            return 0;
        });

        return allPlayers;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 points-table">
                    <br/>

                    <h4>Points Table</h4>
                    
                    <hr />

                    <table className="table table-flex-make">
                        <thead className="table-head">
                            <tr className="head-row">
                                <th scope="col col1">Team</th>
                                <th scope="col col2">Played</th>
                                <th scope="col col3">Won</th>
                                <th scope="col col4">Lost</th>
                                <th scope="col col4">Tied</th>
                                <th scope="col col5">Points</th>
                            </tr>
                        </thead>

                        <tbody className="table-body text-left">
                            {
                                getTeamStatistics().map((team, index) => (
                                    <tr key={index}>       
                                        <td>{team.name}</td>
                                        <td>{team.playedMatches}</td>
                                        <td>{team.wonMatches}</td>
                                        <td>{team.lostMatches}</td>
                                        <td>{team.tiedMatches}</td>
                                        <td>{team.points}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                <div className="col-md-12">
                    <br/>
                    <div className="row">
                        <div className="col-md-4">
                            <h4>Most Runs</h4>

                            <hr />

                            {
                                getMostRuns().map((player, index) => (
                                    <div className="run-div" key={index}>
                                        <div className="run-div1">{player.name}</div>
                                        <div className="run-div2">{player.playerTotalRuns}</div>
                                    </div>
                                ))
                            }

                            <br/><br/>
                        </div>

                        <div className="col-md-4">
                            <h4>Most Wickets</h4>

                            <hr />

                            {
                                getMostWickets().map((player, index) => (
                                    <div className="run-div" key={index}>
                                        <div className="run-div1">{player.name}</div>
                                        <div className="run-div2">{player.playerTotalWicktes}</div>
                                    </div>
                                ))
                            }

                            <br/><br/>
                        </div>

                        <div className="col-md-4">
                            <h4>Most Boundaries</h4>

                            <hr />

                            {
                                getMostBoundaries().map((player, index) => (
                                    <div className="run-div" key={index}>
                                        <div className="run-div1">{player.name}</div>
                                        <div className="run-div2">{player.playerTotalBoundaries}</div>
                                    </div>
                                ))
                            }

                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PointsTableComponent;