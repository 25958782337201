import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "../css/CreateNewTournament.css";
import { environment } from "./environment/environment";

const CreateNewTournamentForm = () => {
  const [step, setStep] = useState(1);
  const [name, setname] = useState("");
  const [numberOfTeams, setnumberOfTeams] = useState("");
  const [leagueTotalMatches, setleagueTotalMatches] = useState("");
  const [oversPerMatch, setoversPerMatch] = useState("");
  const [semiFinalMatches, setsemiFinalMatches] = useState("");
  const [numberOfTeamMembers, setnumberOfTeamMembers] = useState("");
  const [teamDetails, setTeamDetails] = useState([]);

  const handleNumMatchChange = (e) => {
    setleagueTotalMatches(e.target.value);
  };

  const nextStep = () => {
    if (step === 1) {
      // Validate fields in Case 1
      if (
        name === "" ||
        numberOfTeams === "" ||
        leagueTotalMatches === "" ||
        oversPerMatch === "" ||
        semiFinalMatches === "" ||
        numberOfTeamMembers === ""
      ) {
        alert("Please fill in all the fields.");
        return;
      }
    } else if (step === 2) {
      // Validate fields in Case 2
      const hasEmptyFields = teamDetails.some(
        (team) =>
          team.teamName === "" ||
          team.captainName === "" ||
          team.members.some((member) => member === "")
      );
      if (hasEmptyFields) {
        alert("Please fill in all the fields.");
        return;
      }
    }

    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "name":
        setname(e.target.value);
        break;
      case "numberOfTeams":
        setnumberOfTeams(e.target.value);
        break;
      case "leagueTotalMatches":
        setleagueTotalMatches(e.target.value);
        break;
      case "oversPerMatch":
        setoversPerMatch(e.target.value);
        break;
      case "semiFinalMatches":
        setsemiFinalMatches(e.target.value);
        break;
      case "numberOfTeamMembers":
        setnumberOfTeamMembers(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleTeamDetailsChange = (index, property, e) => {
    const newTeamDetails = [...teamDetails];
    if (!newTeamDetails[index]) {
      newTeamDetails[index] = { members: [] }; // Initialize members as an empty array
    }
    const propertyPath = property.split(".");
    if (propertyPath[0] === "members") {
      const memberIndex = parseInt(propertyPath[1]);
      newTeamDetails[index].members[memberIndex] = e.target.value;
    } else {
      newTeamDetails[index][property] = e.target.value;
    }
    setTeamDetails(newTeamDetails);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // props.onSubmit(leagueTotalMatches)
    console.log("Form submitted:", {
      name,
      numberOfTeams,
      leagueTotalMatches,
      oversPerMatch,
      semiFinalMatches,
      numberOfTeamMembers,
      teamDetails,
    });
  

    window.location.href = "/tournament-plan";
  };

  const handleEnterTournamentDetails = async (e) => {
    try {
      const response = await axios.post(environment.api_url + "/api/v1/tournament", {
        name,
        numberOfTeams,
        leagueTotalMatches,
        oversPerMatch,
        semiFinalMatches,
        numberOfTeamMembers,
        teamDetails,
      });
      console.log("Data sent to MongoDB:", response.data);
      // Redirect or show success message to the user
    } catch (error) {
      console.error("Error sending data to MongoDB:", error);
      // Handle error and show error message to the user
    }
  }
  
  const handleTeamDetails = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(environment.api_url + "/api/v1/teams/create", {
        teamDetails,
      });
      console.log("Team details sent to MongoDB:", response.data);
      // Redirect or show success message to the user
    } catch (error) {
      console.error("Error sending team details to MongoDB:", error);
      // Handle error and show error message to the user
    }
  };
  

  // Render different steps based on the current step number
  switch (step) {
    case 1:
      return (
        <div>
          <h2 className="text-center mt-5 create-new-title">
            Create New Tournament
          </h2>
          <div className="container step1-container-cr-new">
            <form onSubmit={nextStep} className="text-left">
              <div className="form-group">
                <label className="label-in-step1">Tournament Name:</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  className="form-control input-in-step1"
                />
                <br />
              </div>
              <div className="form-group">
                <label className="label-in-step1">No. of Teams:</label>
                <input
                  type="number"
                  name="numberOfTeams"
                  value={numberOfTeams}
                  onChange={handleChange}
                  className="form-control input-in-step1"
                />
                <br />
              </div>
              <div className="form-group">
                <label className="label-in-step1">Total No. of Matches:</label>
                <input
                  type="number"
                  name="leagueTotalMatches"
                  value={leagueTotalMatches}
                  onChange={handleNumMatchChange}
                  className="form-control input-in-step1"
                />
                <br />
              </div>
              <div className="form-group">
                <label className="label-in-step1">Overs per Innings:</label>
                <input
                  type="number"
                  name="oversPerMatch"
                  value={oversPerMatch}
                  onChange={handleChange}
                  className="form-control input-in-step1"
                />
                <br />
              </div>
              <div className="form-group">
                <label className="label-in-step1">No. of Semi finals:</label>
                <input
                  type="number"
                  name="semiFinalMatches"
                  value={semiFinalMatches}
                  onChange={handleChange}
                  className="form-control input-in-step1"
                />
                <br />
              </div>
              <div className="form-group">
                <label className="label-in-step1">
                  No. of members per team:
                </label>
                <input
                  type="number"
                  name="numberOfTeamMembers"
                  value={numberOfTeamMembers}
                  onChange={handleChange}
                  className="form-control input-in-step1"
                />
                <br />
              </div>
              <button type="submit" className="btn btn-primary step1-btn" onClick={handleEnterTournamentDetails}>
                Next
              </button>
            </form>
          </div>
        </div>
      );

    case 2:
      const teamDetailForms = [];
      for (let i = 0; i < numberOfTeams; i++) {
        teamDetailForms.push(
          <div key={i} className="container step2-container-cr-new">
            <h2>Team {i + 1} Details:</h2>
            <div className="form-group">
              <label>Team Name:</label>
              <input
                type="text"
                value={teamDetails[i]?.teamName || ""}
                onChange={(e) => handleTeamDetailsChange(i, "teamName", e)}
                className="form-control input-in-step1"
              />
              <br />
            </div>
            <div className="form-group">
              <label>Captain's Name:</label>
              <input
                type="text"
                value={teamDetails[i]?.captainName || ""}
                onChange={(e) => handleTeamDetailsChange(i, "captainName", e)}
                className="form-control input-in-step1"
              />
              <br />
            </div>
            {Array.from({ length: numberOfTeamMembers - 1 }, (_, memberIndex) => (
              <div className="form-group" key={memberIndex}>
                <label>Team Member {memberIndex + 1}:</label>
                <input
                  type="text"
                  value={teamDetails[i]?.members[memberIndex] || ""}
                  onChange={(e) =>
                    handleTeamDetailsChange(i, `members.${memberIndex}`, e)
                  }
                  className="form-control input-in-step1"
                />
                <br />
              </div>
            ))}
          </div>
        );
      }

      return (
        <div className="team-det-height">
          <h2 className="text-center mt-5 create-new-title">
            Enter Team Details
          </h2>
          <div>
            {teamDetailForms}

            <div className="container step2-btns">
              <button
                type="button"
                onClick={prevStep}
                className="btn btn-secondary step2-prev-btn"
              >
                Previous
              </button>
              <button
                type="submit"
                onClick={(e) => {
                  nextStep(e);
                  handleTeamDetails(e);
                }}
                className="btn btn-primary step2-next-btn"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      );

    case 3:
      return (
        <div className="w-100">
          <div className="step3-title mt-5">
            <h2>Confirm Tournament Details:</h2>
          </div>
          <div className="container step3-container-cr-new w-100">
            <div className="tournament-details w-100 col-md-12">
              {/* <p><b>Tournament Name:</b> {name}</p>
        <p><b>No. of Teams:</b> {numberOfTeams}</p>
        <p><b>Total No. of Matches:</b> {leagueTotalMatches}</p>
        <p><b>Overs per Innings:</b> {oversPerMatch}</p>
        <p><b>No. of Semi finals:</b> {semiFinalMatches}</p>
        <p><b>No. of members per team:</b> {numberOfTeamMembers}</p> */}

              <br />

              <div className="t-det">
                <div className="lefts">
                  <p>
                    <b>Tournament Name:</b>{" "}
                  </p>
                  <p>
                    <b>No. of Teams:</b>{" "}
                  </p>
                  <p>
                    <b>Total No. of Matches:</b>{" "}
                  </p>
                  <p>
                    <b>Overs per Innings:</b>{" "}
                  </p>
                  <p>
                    <b>No. of Semi finals:</b>{" "}
                  </p>
                  <p>
                    <b>No. of members per team:</b>{" "}
                  </p>
                </div>

                <div className="rights">
                  <p>{name}</p>
                  <p>{numberOfTeams}</p>
                  <p>{leagueTotalMatches}</p>
                  <p>{oversPerMatch}</p>
                  <p>{semiFinalMatches}</p>
                  <p>{numberOfTeamMembers}</p>
                </div>
              </div>

              <br />
              <div className="divider"></div>
            </div>
            <div className="team-details">
              {teamDetails.map((team, index) => (
                <div key={index} className="step3-items">
                  <h4>Team: {team.teamName}</h4>
                  <p>{team.captainName}-c</p>

                  {team.members.map((member, memberIndex) => (
                    <p key={memberIndex}>{member}</p>
                  ))}
                </div>
              ))}
            </div>
            <div className="container col-md-12">
              <form onSubmit={handleSubmit} className="text-left">
                <div className="step3-btns">
                  <button
                    type="button"
                    onClick={prevStep}
                    className="btn btn-secondary step2-prev-btn"
                  >
                    Previous
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary step2-next-btn"
                  >
                    Done
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default CreateNewTournamentForm;
