import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/ScoreBoard.css";
import { environment } from "./environment/environment";

const CurrentScoreComponentNew = () => {
    const { matchId } = useParams();
    const [match, setMatch] = useState(null);
    const [currentBatsman1, setCurrentBatsman1] = useState("");
    const [currentBatsman2, setCurrentBatsman2] = useState("");
    const [currentBowler, setCurrentBowler] = useState("");

    useEffect(() => {
        fetchTournament();
    }, [matchId]);

    const fetchTournament = async () => {
        try {
            const response = await fetch(environment.api_url + `/api/v1/match-details/${matchId}`);

            if (response.ok) {
                const matchData = await response.json();

                setMatch(matchData.data);

                console.log(matchData.data);

                if (matchData.data && matchData.data.batSecondScoreboard && matchData.data.batSecondScoreboard.playerBowls.length > 0) {
                    window.location.replace("/current-score2/" + matchData.data._id)
                }
                
                if (matchData.data.batFirstScoreboard && matchData.data.batFirstScoreboard.currentlyBatting.length > 0) {
                    setCurrentBatsman1(matchData.data.batFirstScoreboard.currentlyBatting[0]);
                }

                if (matchData.data.batFirstScoreboard && matchData.data.batFirstScoreboard.currentlyBatting.length > 1) {
                    setCurrentBatsman2(matchData.data.batFirstScoreboard.currentlyBatting[1]);
                }

                if (matchData.data.batFirstScoreboard && matchData.data.batFirstScoreboard.currentlyBowling) {
                    setCurrentBowler(matchData.data.batFirstScoreboard.currentlyBowling);
                }
            } else {
                console.error("Failed to fetch match data");
            }
        } catch (error) {
            console.error("Error while fetching match data:", error);
        }
    };

    const toggleNextScoreboard = () => {
        // if(match.status == "BAT_FIRST_WIN" || match.status == "BALL_FIRST_WIN" || match.status == "MATCH_TIED") {
        //     alert("Maytch is already finalized!");

        //     return false;
        // }

        var extraRunsBallsCount = 0;
        
        if(match.batFirstScoreboard == null) {
            alert("Please score on first innings first!");
            return false;
        }

        match.batFirstScoreboard.playerBowls.filter(function (pb) {
            if(pb.status == "WIDE" || pb.status == "NO_BALL") {
                extraRunsBallsCount = extraRunsBallsCount + 1;
            }
        });

        // if(match.batFirstScoreboard.playerBowls.length - extraRunsBallsCount < environment.no_of_overs * environment.balls_per_over) {
        //     alert("You have to ball all " + environment.no_of_overs * environment.balls_per_over + " balls before you proceed to next scoreboard.");

        //     return false;
        // }

        window.open("/score-board2/"+match._id, "_blank");
    };


    if (match === null) {
        return <div className="loading">Loading...</div>;
    }

    function getIdFromUrl(url) {
        const regex = /\/([^/]+)$/;
        const match = url.match(regex);
        if (match) {
            return match[1];
        } else {
            return null;
        }
    }

    const playToss = (teams) => {

        const url = window.location.href;
        const matchId = getIdFromUrl(url);

        return <div>
            {teams[0].name}

            &nbsp; VS &nbsp;

            {teams[1].name}

            <br /><br />

            <a href={`/play-toss/${matchId}`} className="btn btn-success">Play Toss</a>
        </div>
    };

    const batFirstTeam = (batFirst, teams) => {
        var batFirstTeam = teams.filter(function (t) {
            return t.id == batFirst;
        });

        return batFirstTeam[0];
    }

    const ballFirstTeam = (batFirst, teams) => {
        var ballFirstTeam = teams.filter(function (t) {
            return t.id != batFirst;
        });

        return ballFirstTeam[0];
    }

    const getBatterRuns = (playerId, bowls) => {
        if (bowls == null) {
            return "-";
        }

        var playerRuns = 0;

        bowls.filter(function (b) {
            if (b.status == "RUNS" && b.batsmanId == playerId) {
                playerRuns = playerRuns + b.runs;
            }
        });

        return playerRuns;
    }

    const getBowlerRuns = (playerId, bowls) => {
        if (bowls == null) {
            return "-";
        }

        var bowlerRuns = 0;

        bowls.filter(function (b) {
            if (b.status != "WICKET" && b.bowlerId == playerId) {
                bowlerRuns = bowlerRuns + b.runs;
            }
        });

        return bowlerRuns;
    }

    const getBowlerWickets = (playerId, bowls) => {
        if (bowls == null) {
            return "-";
        }

        var bowlerWickets = 0;

        bowls.filter(function (b) {
            if (b.status == "WICKET" && b.bowlerId == playerId) {
                bowlerWickets = bowlerWickets + 1;
            }
        });

        return bowlerWickets;
    }

    const getBatFirstTotalRuns = (bowls) => {
        if (bowls == null) {
            return "-";
        }

        var totalRuns = 0;
        var totalWickets = 0;

        bowls.filter(function (b) {
            if (b.status != "WICKET") {
                totalRuns = totalRuns + b.runs;
            }

            if (b.status == "WICKET") {
                totalWickets = totalWickets + 1;
            }
        });

        return totalRuns + "/" + totalWickets;
    }

    const getTotalWides = (bowls) => {
        if (bowls == null) {
            return "-";
        }

        var totalWides = 0;

        bowls.filter(function (b) {
            if (b.status == "WIDE") {
                totalWides = totalWides + b.runs;
            }
        });

        return totalWides;
    }

    const getTotalNoBalls = (bowls) => {
        if (bowls == null) {
            return "-";
        }

        var totalNoBalls = 0;

        bowls.filter(function (b) {
            if (b.status == "NO_BALL") {
                totalNoBalls = totalNoBalls + b.runs;
            }
        });

        return totalNoBalls;
    }

    const getTotalLegByes = (bowls) => {
        if (bowls == null) {
            return "-";
        }

        var totalLegByes = 0;

        bowls.filter(function (b) {
            if (b.status == "LEG_BYE") {
                totalLegByes = totalLegByes + b.runs;
            }
        });

        return totalLegByes;
    }

    const getPlayer = (playerId, teamPlayers) => {
        if(!playerId) {
            return "";
        }
        
        var player = teamPlayers.filter(function (p) {
            return p._id == playerId;
        });

        return player[0];
    }

    const getSortedBowling = (playerBowls) => {
        return playerBowls.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            return dateB -

                dateA;
        });
    }
    
    const getSingleBallClass = (ballStatus) => {
        if(ballStatus == "WIDE") {
            return "wide-ball";
        } else if(ballStatus == "NO_BALL") {
            return "no-ball";
        } else if(ballStatus == "LEG_BYE") {
            return "legbye-ball";
        }  else if(ballStatus == "WICKET") {
            return "wicket-ball";
        } else {
            return "normal-ball";
        }
    }

    return (
        <>
            {
                match.batFirst != null ? <>
                    <div>
                        <div className="dashboard">
                            <div className="grid-container">
                                <div className="grid-item shadow batting-update">
                                    <div className="container batting-update">
                                        <div className="title-bat-update">
                                            <div className="score-head">
                                                <div className={(match.status == "BAT_FIRST_WIN" || match.status == "BALL_FIRST_WIN" || match.status == "MATCH_TIED") ? "sh1 match-finished" : "sh1"}>
                                                    <h4 className="match-head">
                                                        {match.teams[0].name}

                                                        &nbsp; VS &nbsp;

                                                        {match.teams[1].name}

                                                        {/* <br/>
                                                        
                                                        {
                                                            (match.status == "BAT_FIRST_WIN" || match.status == "BALL_FIRST_WIN" || match.status == "MATCH_TIED") && <p className="match-finish-text">Match is finished!</p>
                                                        } */}
                                                    </h4>
                                                </div>

                                                <div className="sh2">
                                                    <h4 className="match-head">Bat First: {batFirstTeam(match.batFirst, match.teams).name}</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container bat-update-data">
                                            <div className="bat-data-table-titles">
                                                <div className="batter-name">Batter</div>
                                                <div className="on-strike">Currently Playing</div>
                                                <div className="runs-scored">Runs</div>
                                            </div>

                                            <div className="divider"></div>

                                            <div className="data-container">
                                                <div className="player-names-and-data">
                                                    <div className="batter-names">
                                                        <div className="batter-name1">
                                                            <div className="player-name">
                                                                <h5>Batsman 1</h5>

                                                                <select className="form-select shadow"
                                                                    value={currentBatsman1}
                                                                    onChange={(e) =>
                                                                        setCurrentBatsman1((prev) => (e.target.value))
                                                                    }
                                                                >
                                                                    <option value="">No One</option>
                                                                    {
                                                                        batFirstTeam(match.batFirst, match.teams).players.map((player, index) => (
                                                                            <option value={player._id} key={index}>{player.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>

                                                            <div className="player-name">
                                                                <h5>Batsman 2</h5>

                                                                <select className="form-select shadow"
                                                                    value={currentBatsman2}
                                                                    onChange={(e) =>
                                                                        setCurrentBatsman2((prev) => (e.target.value))
                                                                    }
                                                                >
                                                                    <option value="">No One</option>
                                                                    {
                                                                        batFirstTeam(match.batFirst, match.teams).players.map((player, index) => (
                                                                            <option value={player._id} key={index}>{player.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="player-data">
                                                        {
                                                            match.batFirstScoreboard && match.batFirstScoreboard.currentlyBatting.map((batterId, index) => (
                                                                <div className="player-data1" key={index}>
                                                                    <div className="on-strike-data">
                                                                        <div className="selected-player">
                                                                            <div className="on-strike-data">
                                                                                {getPlayer(batterId, batFirstTeam(match.batFirst, match.teams).players).name}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="on-strike-runs">
                                                                        <div className="selected-player">
                                                                            <div className="on-strike-runs">
                                                                                {getBatterRuns(batterId, match.batFirstScoreboard.playerBowls)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-item shadow bowling-update">
                                    <div className="container batting-update">
                                        <div className="title-bat-update">
                                            <div className="score-head">
                                                <div className="sh3">
                                                    <h4 className="match-head">
                                                        Balling Team : {ballFirstTeam(match.batFirst, match.teams).name}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="container bat-update-data">
                                            <div className="bat-data-table-titles">
                                                <div className="batter-name">Current Bowler</div>
                                                <div className="on-strike">Over</div>
                                                <div className="runs-scored">Runs/Wickets</div>
                                            </div>

                                            <div className="divider"></div>

                                            <div className="data-container">
                                                <div className="player-names-and-data">
                                                    <div className="batter-names">
                                                        <div className="player-name">
                                                            <select className="form-select shadow"
                                                                value={currentBowler}
                                                                onChange={(e) =>
                                                                    setCurrentBowler((prev) => (e.target.value))
                                                                }
                                                            >
                                                                <option value="">No One</option>
                                                                {
                                                                    ballFirstTeam(match.batFirst, match.teams).players.map((player, index) => (
                                                                        <option value={player._id} key={index}>{player.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="player-data">
                                                        <div className="player-data1">
                                                            <div className="on-strike-data">
                                                                <div className="selected-player">
                                                                    <div className="on-strike-data">
                                                                        {match.batFirstScoreboard && getPlayer(match.batFirstScoreboard.currentlyBowling, ballFirstTeam(match.batFirst, match.teams).players).name}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="on-strike-runs">
                                                                <div className="selected-player">
                                                                    <div className="on-strike-runs">
                                                                        {match.batFirstScoreboard && getBowlerRuns(match.batFirstScoreboard.currentlyBowling, match.batFirstScoreboard.playerBowls)} /
                                                                        {match.batFirstScoreboard && getBowlerWickets(match.batFirstScoreboard.currentlyBowling, match.batFirstScoreboard.playerBowls)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="bowl-by-ball">
                                            {
                                                match.batFirstScoreboard && getSortedBowling(match.batFirstScoreboard.playerBowls).map((pb, index) => (  
                                                    <div className={"single-ball " + getSingleBallClass(pb.status) } key={index}>
                                                        <div className="bowl-numer">{pb.over}.{pb.ballNumber}</div>
                                                        <div className="bowl-runs">{pb.runs}</div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className="color-codes">
                                            <span className="color-circle wicket-ball"></span> Wicket

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <span className="color-circle wide-ball"></span> Wide

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <span className="color-circle no-ball"></span> No Ball

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <span className="color-circle legbye-ball"></span> Leg Bye

                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;                                          

                                            <span className="color-circle normal-ball"></span> Normal
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-item shadow stat-board">
                                    <div className="score-summary-container">
                                        <div className="left-scoring">
                                            <div className="table-caption">Scoreboard</div>

                                            <div className="table-content score-sec0">
                                                {
                                                    match.batFirstScoreboard && batFirstTeam(match.batFirst, match.teams).players.map((player, index) => (
                                                        <div className="score-sec1" key={index}>
                                                            <div className="score-sec2">
                                                                <div className="p">{player.name}</div>
                                                            </div>

                                                            <div className="score-sec2">
                                                                <div className="p">{getBatterRuns(player._id, match.batFirstScoreboard.playerBowls)}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                <div className="score-sec1">
                                                    <div className="score-sec2">
                                                        <div className="p"><b>Total</b></div>
                                                    </div>

                                                    <div className="score-sec2">
                                                        <div className="p"><b>{match.batFirstScoreboard && getBatFirstTotalRuns(match.batFirstScoreboard.playerBowls)}</b></div>
                                                    </div>
                                                </div>

                                                <div className="score-sec3">
                                                    Wide: {match.batFirstScoreboard && getTotalWides(match.batFirstScoreboard.playerBowls)} &nbsp;&nbsp;&nbsp;&nbsp; No Ball: {match.batFirstScoreboard && getTotalNoBalls(match.batFirstScoreboard.playerBowls)} &nbsp;&nbsp;&nbsp;&nbsp; Leg Bye: {match.batFirstScoreboard && getTotalLegByes(match.batFirstScoreboard.playerBowls)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="right-bowling-figures">
                                            <div className="table-caption">Bowling Stat</div>

                                            <div className="table-content score-sec0">
                                                {
                                                    ballFirstTeam(match.batFirst, match.teams).players.map((player, index) => (
                                                        <div className="score-sec1" key={index}>
                                                            <div className="score-sec2">
                                                                <div className="p">{player.name}</div>
                                                            </div>

                                                            <div className="score-sec2">
                                                                <p className="fs-3">{match.batFirstScoreboard && getBowlerRuns(player._id, match.batFirstScoreboard.playerBowls)}</p>
                                                                <p className="fs-5">/ {match.batFirstScoreboard && getBowlerWickets(player._id, match.batFirstScoreboard.playerBowls)}</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-item shadow score-update">
                                    <div className="bat-bowl-score-update score-large">
                                        {match.batFirstScoreboard && getBatFirstTotalRuns(match.batFirstScoreboard.playerBowls)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    </> : <>

                    <div className="open-div">
                        {
                            playToss(match.teams)
                        }
                    </div>
                </>
            }
        </>
    );
};

export default CurrentScoreComponentNew;
