
import React, { useState } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import HomeComponent from './components/Home';
import NavBarComponent from './components/NavBar';
import CreateNewTournamentForm from './components/CreateNewTournament';
import TournamentPlanForm from './components/TournamentPlan';
import './App.css';
import Toss from './components/Toss';
import CurrentScoreComponent from './components/CurrentScoreComponent';
import TournamentPreviewNewComponent from './components/TournamentPreviewNew';
import ScoreBoardNewComponent from './components/ScoreBoardNew';
import ScoreBoardNew2Component from './components/ScoreBoardNew2';
import PointsTableComponent from './components/PointsTable';
import CurrentScoreComponentNew from './components/CurrentScoreComponentNew';
import CurrentScoreComponentNew2 from './components/CurrentScoreComponentNew2';

function App() {
  const tournamentNames = ["IPL","PSL","BBL"];
  const numMatches = 5;
  const teamDetails =  [
      {
        "captainName": "Dasun",
        "members": ["Dimuth", "Pathum", "Kusal", "Charith", "Sadeera"],
        "teamName": "RR"
      },
      {
        "captainName": "Dananjaya",
        "members": ["Wanindu", "Chamika", "Chameera", "Lahiru", "Pathirana"],
        "teamName": "KK"
      },
      {
        "captainName": "Dasun",
        "members": ["Dimuth", "Pathum", "Kusal", "Charith", "Sadeera"],
        "teamName": "FCB"
      },
      {
        "captainName": "Dananjaya",
        "members": ["Wanindu", "Chamika", "Chameera", "Lahiru", "Pathirana"],
        "teamName": "RM"
      },
      {
        "captainName": "Dasun",
        "members": ["Dimuth", "Pathum", "Kusal", "Charith", "Sadeera"],
        "teamName": "LOP"
      },
      {
        "captainName": "Dananjaya",
        "members": ["Wanindu", "Chamika", "Chameera", "Lahiru", "Pathirana"],
        "teamName": "JUK"
      },
    ]  //from CreateNewTournamentForm

  const tournamentPlan = {
    matches: [
      {
        match: "Match 1",
        inBetween: {
          firstTeam: "RR",
          secondTeam: "CSK",
        },
      },
      {
        match: "Match 2",
        inBetween: {
          firstTeam: "KKR",
          secondTeam: "DC",
        },
      },
      
    ],
  };

  return (
    <Router>
      <div className="App">
        <NavBarComponent/>
      <div className='content'>
      
      <Switch>
        <Route exact path='/'>
          <HomeComponent tournamentNames={tournamentNames}/>
        </Route>

        <Route path='/create-new-tournament'>
          <CreateNewTournamentForm/>
        </Route>

        <Route path='/tournament-plan/:tournamentId'>
          <TournamentPlanForm numMatches={numMatches} teamDetails={teamDetails}/>
        </Route>

        <Route path='/tournament-preview/:tournamentId'>
          <TournamentPreviewNewComponent />
        </Route>

        <Route path='/score-board/:matchId'>
          <ScoreBoardNewComponent />
        </Route>

        <Route path='/score-board2/:matchId'>
          <ScoreBoardNew2Component />
        </Route>

        <Route path='/current-score/:matchId'>
          <CurrentScoreComponentNew />
        </Route>
        
        <Route path='/current-score2/:matchId'>
          <CurrentScoreComponentNew2 />
        </Route>

        <Route path='/points-table/:tournamentId'>
          <PointsTableComponent />
        </Route>

        <Route path='/play-toss/:matchId'>
            <Toss />
        </Route>
      </Switch>
    </div>
    {/* <FooterComponent/> */}
    </div> 
    </Router>
  );
}

export default App;
