import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Toss.css';
import { environment } from "./environment/environment";

const TossComponent = () => {

  const [tossWinTeam, setTossWinTeam] = useState("");
  const [firstBatTeam, setFirstBatTeam] = useState("");
  const { matchId } = useParams();
  const [match, setMatch] = useState(null);
  const [isDataLodingComplete, setIsDataLodingComplete] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    fetchTournament();
  }, [matchId]);

  const fetchTournament = async () => {
    try {
      const response = await fetch(environment.api_url + `/api/v1/match-details/${matchId}`);

      if (response.ok) {
        const matchData = await response.json();

        setMatch(matchData.data);

        console.log(matchData.data);
        setIsDataLodingComplete(true)
      } else {
        console.error("Failed to fetch match data");
      }
    } catch (error) {
      console.error("Error while fetching match data:", error);
    }
  };

  let team1, team2;
  if (isDataLodingComplete == true) {
    team1 = match.teams[0]['name'];
    team2 = match.teams[1]['name'];
  }

  const handleTossWin = (team) => {
    setTossWinTeam(team);
  };

  const handleFirstBat = (team) => {
    setFirstBatTeam(team);
  };

  const handleSubmit = async () => {
    console.log("Toss Winner:", tossWinTeam);
    console.log("First Batting Team:", firstBatTeam);

    let tossWinTeamId, fistBatTeamId;

    if (tossWinTeam == team1) {
      tossWinTeamId = match.teams[0]['_id']
    }
    else {
      tossWinTeamId = match.teams[1]['_id']
    }

    if (firstBatTeam == team1) {
      fistBatTeamId = match.teams[0]['_id']
    }
    else {
      fistBatTeamId = match.teams[1]['_id']
    }

    if (firstBatTeam && tossWinTeam && match._id) {
      try {
        const response = await fetch(environment.api_url + '/api/v1/update-macth-status', {
          method: 'POST',
          body: JSON.stringify({
            matchId: match._id,
            status: "ONGOING",
            tossWin: tossWinTeamId,
            batFirst: fistBatTeamId
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        });

        const data = await response.json();
        console.log(data);

        if (data.status === 200) {

          console.log("tournamentId", match.tournamentId._id);
          const newRedirectUrl = `/tournament-preview/${match.tournamentId._id}`;
          setRedirectUrl(newRedirectUrl);
        }
        else {
          alert("Error when finalizing match. Please see the console for the cause.");
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    else {
      alert("Please fill the form & submit");
    }

  };

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <div className="container step1-container-toss">
      <div className="toss-text">
        <h2 className="text-center mb-3">Coin Toss</h2>
      </div>


      <div className="divider"></div>
      <div className="winner">
        <h5 className="toss-winner-text">Toss Winner is:</h5>
        <div className="input-group team1-text">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <input
                type="radio"
                aria-label="Checkbox for following text input"
                checked={tossWinTeam === team1}
                onChange={() => handleTossWin(team1)}
              ></input>
            </div>
          </div>
          <p className="teamName-in-p">{team1}</p>
        </div>
        <div className="input-group team2-text">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <input
                type="radio"
                aria-label="Checkbox for following text input"
                checked={tossWinTeam === team2}
                onChange={() => handleTossWin(team2)}
              ></input>
            </div>
          </div>
          <p className="teamName-in-p">{team2}</p>
        </div>
      </div>
      <div className="divider"></div>
      <div className="winner">
        <h5 className="toss-winner-text">First batting team is:</h5>
        <div className="input-group team1-text">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <input
                type="radio"
                aria-label="Checkbox for following text input"
                checked={firstBatTeam === team1}
                onChange={() => handleFirstBat(team1)}
              ></input>
            </div>
          </div>
          <p className="teamName-in-p">{team1}</p>
        </div>
        <div className="input-group team2-text">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <input
                type="radio"
                aria-label="Checkbox for following text input"
                checked={firstBatTeam === team2}
                onChange={() => handleFirstBat(team2)}
              ></input>
            </div>
          </div>
          <p className="teamName-in-p">{team2}</p>
        </div>
      </div>
      <div className="divider"></div>

      <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
        Start Match
      </button>


    </div>
  );
};

export default TossComponent;
