import React from 'react';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/NavBar.css';
import EF from '../img/EF.png';

const NavbarComponent = () => {
  return (

    <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-cotainer">

        <div className="nav-left">
          <a href="/"><img src={EF} alt="logo" className='logo'/></a>
          
        
                
        </div>
        <div className="nav-middle">
        <a className="navbar-brand" href="#">Efito Premier League 2023</a>
        </div>
        {/* <div className="nav-right">
        <Dropdown>
      <Dropdown.Toggle id="dropdownMenuButton">
        Menu
      </Dropdown.Toggle>
      <Dropdown.Menu className='dropdownMenu'>
        <Dropdown.Item href="#">T-preview</Dropdown.Item>
        <Dropdown.Item href="#">T-overview</Dropdown.Item>
        <Dropdown.Item href="#">Points Table</Dropdown.Item>
        <Dropdown.Item href="#">SF-preview</Dropdown.Item>
        <Dropdown.Item href="#">SF-overview</Dropdown.Item>
        <Dropdown.Item href="#">Players</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
</div> */}

</nav>
  );
};

export default NavbarComponent;
