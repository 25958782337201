import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../css/TournamentPlan.css";
import { environment } from "./environment/environment";

const TournamentPlanForm = () => {
  const { tournamentId } = useParams();
  const [tournament, setTournament] = useState(null);
  const [teamOptions, setTeamOptions] = useState([]);

  useEffect(() => {
    const fetchTournament = async () => {
      try {
        const response = await fetch(
          environment.api_url + `/api/v1/tournament/${tournamentId}`
        );

        const teamResponse = await fetch(
          environment.api_url + `/api/v1/teams/${tournamentId}`
        );

        if (response.ok && teamResponse.ok) {
          const tournamentData = await response.json();
          const teamData = await teamResponse.json();
          setTournament(tournamentData);
          setTeamOptions(teamData.data);
          console.log("Tournament data:", tournamentData);
          console.log("Team data:", teamData);
        } else {
          console.error("Failed to fetch tournament data");
        }
      } catch (error) {
        console.error("Error while fetching tournament data:", error);
      }
    };

    fetchTournament();
  }, [tournamentId]);

  const renderMatchLabels = () => {
    if (
      tournament &&
      tournament.data &&
      tournament.data.leagueTotalMatches
    ) {
      const totalMatches = tournament.data.leagueTotalMatches;
      const matchLabels = [];

      for (let i = 1; i <= totalMatches; i++) {
        matchLabels.push(
          <div className="form-group match-entry" key={i}>
            <div className="match-label">
              <b>
                <label className="label-match-plan">Match {i}:</label>
              </b>
            </div>

            <div className="select-team">
              <div className="select-first-team">
                <select className="form-select" aria-label="Default select example">
                  <option value="default">Select Team 1</option>
                  {teamOptions.map((team, index) => (
                    <option key={index} value={team.name}>
                      {team.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="vs">
                <p>vs</p>
              </div>
              <div className="select-second-team">
                <select className="form-select" aria-label="Default select example">
                  <option value="default">Select Team 2</option>
                  {teamOptions.map((team, index) => (
                    <option key={index} value={team.name}>
                      {team.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      }

      return matchLabels;
    }

    return null;
  };

  return (
    <div className="container step1-container-plan">
      <form className="text-left">
        <div className="top-plan">{renderMatchLabels()}</div>

        <div className="center-button">
          <Link to="/">
            <button type="submit" className="btn btn-primary step1-btn">
              Save
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default TournamentPlanForm;
