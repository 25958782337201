import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/TournamentPreview.css";
import { environment } from "./environment/environment";

const TournamentPreviewNewComponent = () => {
    const { tournamentId } = useParams();
    const [tournament, setTournament] = useState(null);

    useEffect(() => {
        const fetchTournament = async () => {
            try {
                const response = await fetch(
                    environment.api_url + `/api/v1/matches-by-tournament/${tournamentId}`
                );

                if (response.ok) {
                    const tournamentData = await response.json();

                    setTournament(tournamentData.data);
                } else {
                    console.error("Failed to fetch tournament data");
                }
            } catch (error) {
                console.error("Error while fetching tournament data:", error);
            }
        };

        fetchTournament();
    }, [tournamentId]);

    const getResultStatus = (batFirst, teams, status, matchId) => {
        if (status == 'NOT_STARTED') {
            return "Not Started Yet!";
        } else if (status == 'ONGOING') {
            return <a className='btn btn-info btn-sm' href={'/score-board/' + matchId} _blank="true">Ongoing</a>;
        } else if (status == 'MATCH_TIED') {
            return "Match Tied!";
        } else if (status == 'BAT_FIRST_WIN') {
            var batFirstTeam = teams.filter(function (t) {
                return t.id == batFirst;
            });

            return <a className='btn btn-warning btn-sm' href={'/score-board/' + matchId} _blank="true">{batFirstTeam[0].name + " wins!"}</a>;
        } else if (status == 'BALL_FIRST_WIN') {
            var ballFirstTeam = teams.filter(function (t) {
                return t.id != batFirst;
            });

            return <a className='btn btn-warning btn-sm' href={'/score-board/' + matchId} _blank="true">{ballFirstTeam[0].name + " wins!"}</a>;
        } else {
            return "Not Started Yet!";
        }
    };

    const getTeamTotals = (matchId, batFirst, teams, status, batFirstScoreboard, batSecondScoreboard) => {
        var batFirstTeam = teams.filter(function (t) {
            return t.id == batFirst;
        });

        var ballFirstTeam = teams.filter(function (t) {
            return t.id != batFirst;
        });

        if (status == 'NOT_STARTED') {
            if (teams.length > 0) {
                return <a className='btn btn-primary btn-sm' href={'/score-board/' + matchId} _blank="true">Start Match</a>;
            }
        } else {
            if (!batFirstScoreboard || !batSecondScoreboard) {
                return <div>
                    <a className='btn btn-primary btn-sm match-btn' href={'/score-board/' + matchId} _blank="true">Start Match</a>
                </div>
            }

            var batFirstTotal = 0;
            var batFirstWickets = 0;

            batFirstScoreboard.playerBowls.filter(function (b) {
                if (b.status != "WICKET") {
                    batFirstTotal = batFirstTotal + b.runs;
                }

                if (b.status == "WICKET") {
                    batFirstWickets = batFirstWickets + 1;
                }
            });

            var ballFirstTotal = 0;
            var ballFirstWickets = 0;

            batSecondScoreboard.playerBowls.filter(function (b) {
                if (b.status != "WICKET") {
                    ballFirstTotal = ballFirstTotal + b.runs;
                }

                if (b.status == "WICKET") {
                    ballFirstWickets = ballFirstWickets + 1;
                }
            });

            return <div className="tp-sec1">
                <div className="tp-sec2">
                    {
                        batFirstTeam[0].name.split(" ")[0].charAt(0) + ". " + batFirstTeam[0].name.split(" ")[1].charAt(0) + ". : " + batFirstTotal + "/" + batFirstWickets
                    }

                    &nbsp;&nbsp; | &nbsp;&nbsp;

                    {
                        ballFirstTeam[0].name.split(" ")[0].charAt(0) + ". " + ballFirstTeam[0].name.split(" ")[1].charAt(0) + ". : " + ballFirstTotal + "/" + ballFirstWickets
                    }
                </div>

                <div className="tp-sec2">
                    <a className='btn btn-primary btn-dark btn-sm' href={'/current-score/' + matchId} _blank="true">Live Scoreboard</a>
                </div>
            </div>
        }
    };

    if (tournament === null) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="container-fluid score-card">
            <div className="row">
                <div className="col-md-12 ">
                    <br />

                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th>Match Id</th>
                                <th>Type</th>
                                <th>Teams</th>
                                <th>Total</th>
                                <th>Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tournament.map((t, index) => (
                                    <tr className="team-row" key={index}>
                                        <td>Match {index + 1}</td>
                                        <td>{t.type}</td>
                                        <td className="team-sec">
                                            {
                                                <>
                                                    {
                                                        t.teams.map((team, index1) => (
                                                            <div>
                                                                <div className="team" key={index1}>{team.name}</div>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            }

                                            {t.teams.length == 0 &&
                                                <>-</>
                                            }
                                        </td>

                                        <td className="team-sec1">
                                            {getTeamTotals(t._id, t.batFirst, t.teams, t.status, t.batFirstScoreboard, t.batSecondScoreboard)}
                                        </td>

                                        <td>
                                            <div className="win-status">
                                                {getResultStatus(t.batFirst, t.teams, t.status, t._id)}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <a href={"/points-table/" + tournamentId} className="btn btn-success points-table">Points Table</a>
                </div>
            </div>
        </div>
    );
};

export default TournamentPreviewNewComponent;